import React, {Component} from 'react';
import {Button, Form, Header, Checkbox} from "semantic-ui-react";
import Provider from '../../../Services/ARADataProvider';
import ContingentButton from "../../ContingentButton";

class ARAForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            network: '',
            platform: '',
            contentType: '',
            properties: '{}'
        };

        this.onClickSave = this.onClickSave.bind(this);
        this.onClickDuplicate = this.onClickDuplicate.bind(this);
    }

    onClickSave() {
        try {
            let properties = JSON.parse(this.state.properties);
            let profile = {
                _id: this.props.selectedKey,
                profile: Object.keys(properties).reduce((accumulator, key) => {
                    let property = properties[key];
                    if (key && property)
                        accumulator[key] = property;
                    return accumulator;
                }, {})
            };
            this.props.onClickSave(profile);
        } catch (e) {
            console.error(e);
        }
    }

    onClickDuplicate() {
        this.props.onClickDuplicate();
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.selectedKey && nextProps.selectedKey !== this.props.selectedKey) {
            if (!nextProps.newClicked) {
                Provider.get(nextProps.selectedKey).then(data => {
                    try{
                        if(data.error){
                            if(data.error.response.request.status === 401){
                                location.reload()
                            }
                            if(data.error.response.request.status === 403){
                                throw "Error", "Status Code 403 Unauthorized. Please click the synapse home button at the top to return to the home page"
                            }
                        }
                    } catch(error){
                        this.props.toast(error)
                    }

                    this.props.changeNetwork({target: {value: data.network || ""}});
                    this.props.changeAppId({target: {value: data.appId || ""}})
                    this.props.changePlatform({target: {value: data.platform || ''}});
                    this.props.changeProduct({target: {value: data.product || ""}});
                    this.props.changeContactEmail({target: {value: data.contactEmail || ""}});
                    this.props.changeContactName({target: {value: data.contactName || ""}});
                    this.props.changeTechName({target: {value: data.techName || ""}});
                    this.props.changeTechEmail({target: {value: data.techEmail || ""}});
                    this.props.changeComments({target: {value: data.comments || ""}});
                    this.props.changeMetadata({target: {value: JSON.stringify(data.metadata) || ""}});
                    this.props.toggleBlacklist({target: {checked: data.blacklisted}});
                    this.props.changeToken({target: {value: data.token || ""}});
                });
            } else if (nextProps.newClicked && nextProps.newClicked !== this.props.newClicked) {
                this.props.changeNetwork({target: {value: ""}});
                this.props.changeAppId({target: {value: ""}})
                this.props.changePlatform({target: {value: ""}});
                this.props.changeProduct({target: {value: ""}});
                this.props.changeContactEmail({target: {value: ""}});
                this.props.changeContactName({target: {value: ""}});
                this.props.changeTechName({target: {value: ""}});
                this.props.changeTechEmail({target: {value: ""}});
                this.props.changeComments({target: {value: ""}});
                this.props.changeMetadata({target: {value: ''}});
                this.props.toggleBlacklist({target: {checked: false}});
                this.props.changeToken({target: {value: ""}});
            }
        }
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.props.token)
    }

    render() {
        return (
            <Form>
                <ContingentButton
                    onClick={this.props.onClickSave}
                    service={this.props.service}
                    module={this.props.module}
                    allPermissions={this.props.permissions}
                    scope="all"
                    user={this.props.user}
                >Save</ContingentButton>
                {this.props.token && <Button onClick={this.copyToClipboard}>Copy Token</Button>}
                <br/>
                <br/>
                <Form.Field>
                    <Header htmlFor="selectedKey">{this.props.newClicked ? 'New App Id' : this.props.selectedKey}</Header>

                    <Checkbox id="blacklist" label="Blacklist" toggle checked={this.props.blacklisted} onChange={this.props.toggleBlacklist} />
                </Form.Field>
                <Form.Field>
                    <label htmlFor="network">Network</label>
                    <input type="text" disabled={!this.props.newClicked} placeholder="Network" id="network" onChange={this.props.changeNetwork} value={this.props.network} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="platform">Platform</label>
                    <input type="text" disabled={!this.props.newClicked} placeholder="Platform" id="platform" onChange={this.props.changePlatform} value={typeof(this.props.platform) === 'object' ? this.props.platform.join() : this.props.platform} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="product">Product</label>
                    <input type="text" disabled={!this.props.newClicked} placeholder="Product" id="product" onChange={this.props.changeProduct} value={this.props.product} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="contactName">Contact Name</label>
                    <input type="text" placeholder="Contact Name" id="contactName" onChange={this.props.changeContactName} value={this.props.contactName} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="contactEmail">Contact Email</label>
                    <input type="text" placeholder="Contact Email" id="contactEmail" onChange={this.props.changeContactEmail} value={this.props.contactEmail} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="techName">Tech Name</label>
                    <input type="text" placeholder="Tech Name" id="techName" onChange={this.props.changeTechName} value={this.props.techName} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="techEmail">Tech Email</label>
                    <input type="text" placeholder="Tech Email" id="techEmail" onChange={this.props.changeTechEmail} value={this.props.techEmail} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="comments">Comments</label>
                    <textarea placeholder="Comments" id="comments" onChange={this.props.changeComments} value={this.props.comments} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="metadata">Metadata</label>
                    <textarea placeholder="Metadata" id="metadata" onChange={this.props.changeMetadata} value={this.props.metadata} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}} />
                </Form.Field>
                <Form.Field>
                    <label htmlFor="token">Token</label>
                    <textarea type="text" disabled={true} placeholder="Token" id="token" onChange={this.props.changeToken} value={this.props.token} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
            </Form>
        );
    }
}

export default ARAForm;
