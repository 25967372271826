import React, {useState, useEffect} from "react";
import {Container, Segment, Header, Form, Icon, Modal} from "semantic-ui-react";

export default function AccioCreateEvent(props) {
    const [userId, setUserId] = useState("");
    const [eventId, setEventId] = useState("");
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    console.log(`PROPS: ${JSON.stringify(props)}`);

    const submitCreateEvent = () => {
        let events = [eventId];
        return props.AccioDataProvider.submitAddEvent(userId, events).then(response => {
            console.log("AccioEventsAuditMasterDetailView.submitCreateEvent: response: ", response);
            setSaveButtonDisabled(true);
        }).catch(error => {
            console.error(error);
            setErrorModalOpen(true);
        }).finally(() => {
            console.log("Create Event Success!");
            setModalOpen(true);
            setEventId("");
        });
    };

    const clearFields = () => {
        setUserId("");
        setEventId("");
    };

    useEffect(function checkRequiredFields() {
        if ((userId !== "") && (eventId !== "")) {
            setSaveButtonDisabled(false);
        } else {
            setSaveButtonDisabled(true);
        }
    }, [userId, eventId]);

    return (
        <Container>
            <Segment>
            <Header as="h2">Create Event</Header>
            <Form>
                <Form.Group className="masterContainer">
                    <Form.Input
                        label="User ID"
                        width={13}
                        value={userId}
                        onChange={(event, {value}) => setUserId(value)}
                    />
                    <Form.Select
                        label="Event ID"
                        options={props.eventOptions}
                        width={13}
                        value={eventId}
                        onChange={(event, {value}) => setEventId(value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Button
                        fluid
                        width={4}
                        style={{"margin-left": "3em"}}
                        disabled={saveButtonDisabled}
                        color={"blue"}
                        onClick={() => submitCreateEvent(userId, eventId)}
                    >Save</Form.Button>
                <Form.Button
                    fluid
                    width={4}
                    style={{"margin-left": "3em"}}
                    color={"grey"}
                    onClick={() => clearFields()}
                >Clear</Form.Button>
                </Form.Group>
            </Form>
            </Segment>
            <Modal
                onClose={() => setModalOpen(false)}
                onOpen={() => setModalOpen(true)}
                open={modalOpen}
                >
            <Modal.Header><Icon name='check'/>Create User Event</Modal.Header>
                <Modal.Content>
                    <p>Event created for user "{userId}"</p>
                </Modal.Content>
            </Modal>
            <Modal
                onClose={() => setErrorModalOpen(false)}
                onOpen={() => setErrorModalOpen(true)}
                open={errorModalOpen}
            >
                <Modal.Header><Icon name='exclamation'/>Error Creating Event</Modal.Header>
                <Modal.Content>
                    <p>Unable to create user event. Please try again.</p>
                </Modal.Content>
            </Modal>
        </Container>
    );
}