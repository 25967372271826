import React, {useState, useEffect} from "react";
import {Button, Confirm, Container, Grid, Icon} from "semantic-ui-react";
import AccioEventsAdminDetailPane from "./AccioEventsAdminDetailPane";
import AccioDataProvider from "../../../Services/AccioDataProvider";
import ReactTable from "react-table-v6";
import ContingentButton from "../../ContingentButton";

AccioDataProvider.init(process.env.ACCIO_URL);

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};


export default function AccioEventsAdminMasterDetailView(props) {
    const [newClicked, setNewClicked] = useState(false);
    const [events, setEvents] = useState([]);
    const [selectedKey, setSelectedKey] = useState("");
    const [selectedEvent, setSelectedEvent] = useState({});
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [metadata, setMetadata] = useState({});

    useEffect(function initMetadata() {
        loadEvents();
    }, []);

    useEffect(function initEvents() {
        const updatedEvents = [];
        if (metadata.hasOwnProperty("events")) {
            for (const key of Object.keys(metadata.events)) {
                updatedEvents.push(Object.assign({name: key, last_update: 0}, metadata.events[key]));
            }
        }
        setEvents(updatedEvents);
    }, [JSON.stringify(metadata)]);

    useEffect(function updatedSelectedEvent() {
        let updatedSelectedEvent = {};
        if (selectedKey.length > 0) {
            for (const event of events) {
                if (event.name === selectedKey) {
                    updatedSelectedEvent = Object.assign({}, event);
                }
            }
            setNewClicked(false);
            setSuccessMessage("");
            setErrorMessage("");
        }
        setSelectedEvent(updatedSelectedEvent);
    }, [selectedKey]);

    const loadEvents = (updatedSelectedKey="") => {
        setLoading(true);

        AccioDataProvider.getMetadata().then(response => {
            if (response.hasOwnProperty("metadata")) {
                setMetadata(Object.assign({}, response.metadata));
                setSelectedKey(updatedSelectedKey);
            } else {
                setMetadata({});
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const saveEvent = (name, description, points) => {
        const eventsToSave = {};

        const currentEvents = [];
        for (const event of events) {
            currentEvents.push(Object.assign({}, event));
        }
        for (const event of currentEvents) {
            if (event.hasOwnProperty("name")) {
                const eventName = event.name;
                delete event.name;
                eventsToSave[eventName] = Object.assign({}, event);
            }
        }

        eventsToSave[name] = {
            description,
            points,
            last_update: Date.now() / 1000
        };

        setSaving(true);
        AccioDataProvider.setMetadata("events", eventsToSave).then(response => {
            console.log(response);
            loadEvents(name);
            setSuccessMessage(`The "${name}" event has successfully been updated.`);
        }).catch(error => {
            console.error(error);
            setErrorMessage(`There was an error saving the ${name} event'.`);
        }).finally(() => {
            setSaving(false);
        });
    };

    const deleteEvent = () => {
        const eventsToSave = {};
        for (const event of events) {
            const eventName = event.name;
            delete event.name;
            if (eventName !== selectedKey) {
                eventsToSave[eventName] = Object.assign({}, event);
            }
        }

        setSaving(true);
        AccioDataProvider.setMetadata("events", eventsToSave).then(response => {
            console.log(response);
            loadEvents();
        }).finally(() => {
            setSaving(false);
            setConfirmDeleteOpen(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Grid.Column width={10} className="masterContainer">
                    <Grid>
                        <Grid.Column floated="right" width={16}>
                            <Container fluid textAlign="right" className="actionBarContainer">
                                <Button onClick={loadEvents} size="mini"><Icon name="refresh" />Refresh</Button>
                                <Confirm
                                    open={confirmDeleteOpen}
                                    content={`Are you sure you want to delete the event ${selectedKey}?`}
                                    onCancel={() => setConfirmDeleteOpen(false)}
                                    onConfirm={deleteEvent}
                                />
                                <ContingentButton
                                    onClick={() => setNewClicked(true)}
                                    size="mini"
                                    service={props.service}
                                    module={props.module}
                                    scope="all"
                                    user={props.user}
                                    allPermissions={props.permissions}
                                ><Icon name="plus" />Create New</ContingentButton>
                            </Container>
                        </Grid.Column>
                    </Grid>
                    <ReactTable
                        columns={[
                            {Header: "Event Name", accessor: "name"},
                            {Header: "Last Updated", accessor: "last_update", filterable: false,
                                Cell: row => <span>{new Date(row.value * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>,
                            },
                            {
                                Header: "Points", accessor: "points"
                            }
                        ]}
                        data={events}
                        filterable
                        defaultFilterMethod={(filter, rows) => rows[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
                        loading={loading}
                        className="-striped -highlight"
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick(event, handleOriginal) {
                                    console.log(rowInfo);
                                    setSelectedKey(rowInfo.original.name);
                                },
                                style: {
                                    background: rowInfo && rowInfo.original && selectedKey === rowInfo.original.name ? 'rgba(65, 83, 175, .5)' : '',
                                }
                            }
                        }}
                        style={{height: '75vh'}}
                    />
                </Grid.Column>
                <Grid.Column width={6} className="masterContainer">
                    <AccioEventsAdminDetailPane
                        selectedEvent={selectedEvent}
                        newClicked={newClicked}
                        saveEvent={saveEvent}
                        onClickDelete={() => setConfirmDeleteOpen(true)}
                        service={props.service}
                        module={props.module}
                        user={props.user}
                        permissions={props.permissions}
                        errorMessage={errorMessage}
                        successMessage={successMessage}
                        saving={saving}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
