import React, {useState, useEffect} from "react";
import {Confirm, Form, Grid, Icon, Input, Message, Popup, Select} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";

export default function AccioMaturityDetailView(props) {
    const [initialCountrySettings, setInitialCountrySettings] = useState({});
    const [countryCode, setCountryCode] = useState("");
    const [countryOptions, setCountryOptions] = useState([]);
    const [ageOfMaturity, setAgeOfMaturity] = useState(18);
    const [countryName, setCountryName] = useState("");
    const [paddingDays, setPaddingDays] = useState(1);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    useEffect(function onCountryChange() {
        const updatedInitialCountrySettings = Object.assign(props.selectedCountry);
        if (updatedInitialCountrySettings.abbreviation === "DEFAULT") {
            updatedInitialCountrySettings.name = "DEFAULT";
        }
        setInitialCountrySettings(updatedInitialCountrySettings);
        console.log("AccioMaturityDetailView.onCountryChange: ", props.selectedCountry);
        if (Object.keys(updatedInitialCountrySettings).length > 0) {
            setCountryCode(updatedInitialCountrySettings.abbreviation || "");
            setCountryName(updatedInitialCountrySettings.name || "");
            setPaddingDays(updatedInitialCountrySettings.padding_days || 1);
            setAgeOfMaturity(updatedInitialCountrySettings.maturity_age || 18);
        }
    }, [JSON.stringify(props.selectedCountry)]);

    useEffect(function updateUnsavedChanges() {
        const countryCodeUpdated = countryCode !== initialCountrySettings.abbreviation;
        const countryNameUpdated = countryName !== initialCountrySettings.name;
        const ageOfMaturityUpdated = parseInt(ageOfMaturity) !== parseInt(initialCountrySettings.maturity_age);
        const paddingDaysUpdated = parseInt(paddingDays) !== parseInt(initialCountrySettings.padding_days);
        console.log("AccioMaturityDetailView.updateUnsavedChanges: country code updated? ", countryCodeUpdated, "current: ", countryCode, "initial: ", initialCountrySettings.abbreviation);
        console.log("AccioMaturityDetailView.updateUnsavedChanges: country name updated? ", countryNameUpdated, "current: ", countryName, "initial: ", initialCountrySettings.name);
        console.log("AccioMaturityDetailView.updateUnsavedChanges: age of maturity updated? ", ageOfMaturityUpdated, "current: ", ageOfMaturity, "initial: ", initialCountrySettings.maturity_age);
        console.log("AccioMaturityDetailView.updateUnsavedChanges: padding in days updated? ", countryCodeUpdated, "current: ", paddingDays, "initial: ", initialCountrySettings.padding_days);
        setUnsavedChanges(countryCodeUpdated || countryNameUpdated || ageOfMaturityUpdated || paddingDaysUpdated);
    }, [JSON.stringify(initialCountrySettings), countryCode, ageOfMaturity, countryName, paddingDays]);

    useEffect(function onClickNew() {
        if (props.newClicked) {
            setCountryCode("");
            setCountryName("");
            setPaddingDays(1);
            setAgeOfMaturity(18);
        }
    }, [props.newClicked]);

    useEffect(function onChangeCountryCode() {
        if (countryCode !== initialCountrySettings.abbreviation) {
            if (props.CountryCodes.hasOwnProperty(countryCode.toLowerCase())) {
                const updatedCountryName = props.CountryCodes[countryCode.toLowerCase()];
                setCountryName(updatedCountryName);
            } else if (countryCode === "DEFAULT") {
                setCountryName("DEFAULT");
            } else {
                setCountryName("");
            }
        }
    }, [countryCode]);

    const confirmDelete = () => {
        props.deleteSelectedCountry();
        setDeleteConfirmationOpen(false);
    };


    return (
        <Grid className="masterContainer">
            {
                props.loadingErrorMessage ?
                    <Message icon color="red">
                        <Icon name="exclamation" />
                        <Message.Content>{props.loadingErrorMessage}</Message.Content>
                    </Message> :
                props.saving ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>
                            Saving age-of-maturity settings...
                        </Message.Content>
                    </Message> :
                props.loading ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>
                            Loading age-of-maturity settings...
                        </Message.Content>
                    </Message> :
                !props.newClicked && Object.keys(props.selectedCountry).length < 1 ?
                    <Message icon color="yellow">
                        <Icon name="info" />
                        <Message.Content>
                            Please select a country to continue.
                        </Message.Content>
                    </Message> :
                    <Grid.Column width={16}>
                        <Form>
                            {
                                props.saveSuccessMessage ?
                                    <Message icon color="green">
                                        <Icon name="check" />
                                        <Message.Content>{props.saveSuccessMessage}</Message.Content>
                                    </Message> : ""
                            }
                            <Form.Field
                                label="Country Code"
                                control={Select}
                                options={props.newClicked ? props.newCountryOptions : props.existingCountryOptions}
                                value={countryCode}
                                onChange={(event, {value}) => setCountryCode(value)}
                                disabled={countryName === "DEFAULT" || !props.userCanEdit}
                                fluid
                            />
                            <Form.Field
                                label="Country Name"
                                control={Input}
                                value={countryName}
                                onChange={(event, {value}) => setCountryName(value)}
                                fluid
                                disabled
                            />
                            <Form.Field
                                label="Age of Maturity"
                                control={Input}
                                value={ageOfMaturity}
                                onChange={(event, {value}) => setAgeOfMaturity(value)}
                                fluid
                                type="number"
                                readOnly={!props.userCanEdit}
                            />
                            <Form.Field
                                label="Padding in Days"
                                control={Input}
                                value={paddingDays}
                                onChange={(event, {value}) => setPaddingDays(value)}
                                fluid
                                type="number"
                                readOnly={!props.userCanEdit}
                            />
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <Popup
                                        open={unsavedChanges === true && props.userCanEdit}
                                        content="You have made unsaved changes."
                                        position="bottom left"
                                        trigger={
                                            <ContingentButton
                                                primary
                                                service={props.service}
                                                module={props.module}
                                                scope={"all"}
                                                allPermissions={props.permissions}
                                                user={props.user}
                                                onClick={() => {props.saveSettings(countryCode, countryName, ageOfMaturity, paddingDays)}}
                                            >Save</ContingentButton>
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Confirm
                                        open={deleteConfirmationOpen}
                                        onCancel={() => setDeleteConfirmationOpen(false)}
                                        onConfirm={confirmDelete}
                                        content={`Are you sure you want to delete maturity settings for ${countryName}?`}
                                    />
                                    <ContingentButton
                                        service={props.service}
                                        module={props.module}
                                        scope={"all"}
                                        allPermissions={props.permissions}
                                        user={props.user}
                                        color="red"
                                        floated="right"
                                        onClick={() => setDeleteConfirmationOpen(true)}
                                        disabled={countryCode === "DEFAULT"}
                                    >Delete Country</ContingentButton>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
            }
        </Grid>
    );
};
