import React, {Component} from 'react';
import {Button, Container, Header, Grid, Icon} from "semantic-ui-react";
import ReactTable from 'react-table-v6';
import ARAForm from "./ARAForm";
import Provider from "../../../Services/ARADataProvider";
import ContingentButton from "../../ContingentButton";

class ARAMasterDetailView extends Component {
    isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            filtering: false,
            selectedKey: '',
            newClicked: false,
            appId: '',
            blacklisted: false,
            comments: '',
            contactEmail: '',
            contactName: '',
            createTime: '',
            lastUpdate: '',
            network: '',
            platform: '',
            product: '',
            techEmail: '',
            techName: '',
            token: '',
            metadata: ''
        };

        this.loadData = this.loadData.bind(this);
        this.onClickNew = this.onClickNew.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.authenticated !== nextProps.authenticated) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.isMounted = true;
        Provider.init({baseURL: process.env.ARA_URL});
        this.loadData();
    }

    loadData() {
        this.setState({loading: true}, () => {
            Provider.getAll().then(data => {
                try {
                    if(data.error) {
                        let message = `Status Code: ${data.error.response.request.status}: ${data.error.response.data.error}`;

                        if(data.error.response.request.status === 401) {
                            message += " A login window should appear to allow you to re-login";
                        }
                        if(data.error.response.request.status === 403) {
                            message += " Speak to an admin about getting permissions";
                        }
                        data = [];

                        throw message;
                    }
                    if (this.isMounted) {
                        this.setState({data}, () => this.setState({loading: false}));
                    }
                } catch(error) {
                    this.props.toast("Error", error);
                }
            });
        });
    }

    onClickNew() {
        this.setState({
            newClicked: true,
            network: '',
            platform: '',
            product: '',
            contactEmail: '',
            contactName: '',
            techEmail: '',
            techName: '',
            metadata: '',
            comments: '',
            blacklisted: false,
            token: ''
        });
    }

    onClickSave() {
        try {
            let metadata = {};

            if(this.state.metadata)
                metadata = JSON.parse(this.state.metadata);

            let appInfo = {
                _id: this.state.appId,
                appId: this.state.appId,
                network: this.state.network,
                product: this.state.product,
                contactEmail: this.state.contactEmail || '',
                contactName: this.state.contactName || '',
                techEmail: this.state.techEmail || '',
                techName: this.state.techName || '',
                comments: this.state.comments || '',
                blacklisted: this.state.blacklisted || false,
                metadata: metadata
            };

            if(!this.state.network || !this.state.product || !this.state.platform) {
                throw "Network, product, and/or platforms missing";
            }

            if (this.state.newClicked) {
                appInfo.platforms = this.state.platform.replace(/\s/g, '').split(/,/);

                Provider.new(appInfo).then(response => {
                    this.setState({newClicked: false});
                    this.loadData();
                }).catch(error => {
                    console.error(error);
                });
            } else {
                appInfo.platform = this.state.platform;

                Provider.update(appInfo).then(() => {
                    this.loadData();
                }).catch(error => {
                    console.error(error);
                });
            }
        } catch (error) {
            console.error(error);

            if(error instanceof SyntaxError) {
                this.props.toast("Error", "Invalid JSON provided")
            }
            else {
                this.props.toast("Error", error);
            }
        }
    }

    changeNetwork = event => this.setState({network: event.target.value});
    changePlatform = event => this.setState({platform: event.target.value});
    changeProduct = event => this.setState({product: event.target.value});
    changeAppId = event => this.setState({appId: event.target.value});
    changeContactName = event => this.setState({contactName: event.target.value});
    changeContactEmail = event => this.setState({contactEmail: event.target.value});
    changeTechName = event => this.setState({techName: event.target.value});
    changeTechEmail = event => this.setState({techEmail: event.target.value});
    changeComments = event => this.setState({comments: event.target.value});
    changeMetadata = event => this.setState({metadata: event.target.value});
    toggleBlacklist = event => this.setState({ blacklisted: event.target.checked});
    changeToken = event => this.setState({token: event.target.value});

    render() {
        let that = this; // What is the purpose of this?

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={11} className='masterContainer'>
                        <Grid>
                            <Grid.Column floated='right' width={16}>
                                <Container fluid textAlign='right' className='actionBarContainer'>
                                    <Button onClick={this.loadData} size='mini'><Icon name="refresh"/>Refresh</Button>
                                    <ContingentButton
                                        onClick={this.onClickNew}
                                        size='mini'
                                        service={this.props.service}
                                        module={this.props.module}
                                        scope="all"
                                        allPermissions={this.props.permissions}
                                        user={this.props.user}
                                    ><Icon name="plus"/>Create New</ContingentButton>
                                </Container>
                            </Grid.Column>
                        </Grid>
                        <ReactTable
                            columns={[
                                { accessor: '_id', show: false },
                                { Header: "ID", accessor: "appId" },
                                { Header: "Network", accessor: "network" },
                                { Header: "Platform", accessor: "platform" },
                                { Header: "Product", accessor: "product" },
                                {
                                    Header: "Last Update",
                                    accessor: "lastUpdate",
                                    filterable: false,
                                    Cell: (cell) => {
                                        return(new Date(cell.value).toDateString());
                                    },
                                    sortMethod: (a, b, desc) => {
                                        if(a > b) return 1;
                                        if(a < b) return -1;
                                        return 0;
                                    }
                                }
                            ]}
                            defaultSorted={[{id: "appId", asc: true }]}
                            data={this.state.data}
                            loading={this.state.loading}
                            filterable
                            defaultFilterMethod={(filter, rows) => {
                                if(rows[filter.id] !== null && rows[filter.id] !== undefined)
                                    return rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                            }}
                            className="-striped -highlight"
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        that.setState({
                                            selectedKey: rowInfo.row._id,
                                            newClicked: false
                                        });
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.row && that.state.selectedKey === rowInfo.row._id ? 'rgba(65, 83, 175, .5)' : '',
                                    }
                                }
                            }}
                            style={{height: '75vh'}}
                            defaultPageSize={100}
                        />
                    </Grid.Column>
                    <Grid.Column width={5} className='detailsContainer'>
                        <ARAForm
                            selectedKey={this.state.selectedKey}
                            newClicked={this.state.newClicked}
                            onClickSave={this.onClickSave}
                            network={this.state.network}
                            changeNetwork={this.changeNetwork}
                            changePlatform={this.changePlatform}
                            changeProduct={this.changeProduct}
                            changeContactName={this.changeContactName}
                            changeContactEmail={this.changeContactEmail}
                            changeTechName={this.changeTechName}
                            changeTechEmail={this.changeTechEmail}
                            changeComments={this.changeComments}
                            changeMetadata={this.changeMetadata}
                            toggleBlacklist={this.toggleBlacklist}
                            changeToken={this.changeToken}
                            changeAppId={this.changeAppId}
                            provider={Provider}
                            listToDatalist={this.props.listToDatalist}
                            appId={this.state.appId}
                            product={this.state.product}
                            platform={this.state.platform}
                            contactEmail={this.state.contactEmail}
                            contactName={this.state.contactName}
                            techEmail={this.state.techEmail}
                            techName={this.state.techName}
                            comments={this.state.comments}
                            metadata={this.state.metadata}
                            blacklisted={this.state.blacklisted}
                            token={this.state.token}
                            user={this.props.user}
                            service={this.props.service}
                            module={this.props.module}
                            permissions={this.props.permissions}
                            userPermissions={this.props.userPermissions}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default ARAMasterDetailView;
