import React, {useState, useEffect} from "react";
import {Grid, Tab} from "semantic-ui-react";
import AccioEventsAudit from "./AccioEventsAudit";
import AccioCreateEvent from "./AccioCreateEvent";
import AccioDataProvider from "../../../Services/AccioDataProvider"

AccioDataProvider.init(process.env.ACCIO_URL);

export default function AccioEventsAuditMasterDetailView(props) {
    const [eventOptions, setEventOptions] = useState([]);

    useEffect(function initEventOptions() {
        AccioDataProvider.getMetadata().then(response => {
            const updatedEventOptions = [];
            if (response.hasOwnProperty("metadata") && response.metadata.hasOwnProperty("events")) {
                for (const event of Object.keys(response.metadata.events)) {
                    updatedEventOptions.push({key: event, text: event, value: event});
                }
            }
            setEventOptions(updatedEventOptions);
        }).catch(error => {
            console.error(error);
            setEventOptions([]);
        });
    }, []);


    const generateCSV = (readableAuditData) => {
        console.log(`AccioEventsAuditMasterDetailView.generateCSV: auditData: ${auditData}`);
        let headers = ["User, Event, Points, Timestamp"];
        let auditRows = auditData.reduce((acc, auditEvent) => {
            const { user_id, event_id, points, timestamp } = auditEvent;
            acc.push([user_id, event_id, points, timestamp].join(','));
            return acc
        }, [])
        return [...headers, ...auditRows].join("\n");
    };

    return (
        <Tab
            panes={[
                {
                    menuItem: {
                        key: "event-audit",
                        content: "Event Audit"
                    },
                    render: () =>
                        <AccioEventsAudit
                            AccioDataProvider={AccioDataProvider}
                            eventOptions={eventOptions}
                        />
                },
                {
                    menuItem: {
                        key: "create-event",
                        content: "Create Event"
                    },
                    render: () =>
                        <AccioCreateEvent
                            AccioDataProvider={AccioDataProvider}
                            eventOptions={eventOptions}
                        />
                }
            ]}
        />
    )
};
