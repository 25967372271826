import React, {useState, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import AccioDataProvider from "../../../Services/AccioDataProvider";
import AccioCountrySelector from "./AccioCountrySelector";
import AccioMaturityDetailView from "./AccioMaturityDetailView";

import CountryCodes from "../../../Utils/CountryCodes";

export default function AccioMaturityMasterDetailView(props) {
    const [existingCountryOptions, setExistingCountryOptions] = useState([]);
    const [newCountryOptions, setNewCountryOptions] = useState([]);
    const [countries, setCountries] = useState({});
    const [newClicked, setNewClicked] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [userCanEdit, setUserCanEdit] = useState(false);

    useEffect(function initializeCountries() {
        refreshCountries();
    }, []);

    useEffect(function updateUserCanEdit() {
        let updatedUserCanEdit = props.user.authz.includes("urn:all:aspen-power");

        if (!updatedUserCanEdit) {
            if (props.user.hasOwnProperty("authz") && Array.isArray(props.user.authz)) {
                for (const permission of props.permissions.slice()) {
                    if (props.user.authz.includes(permission.urn) && permission.service === props.service && permission.module === props.module && permission.role === "editor") {
                        updatedUserCanEdit = true;
                        break;
                    }
                }
            }
        }

        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.user), JSON.stringify(props.permissions)]);

    useEffect(function updateCountryOptions() {
        const updatedNewCountryOptions = [];
        const updatedExistingCountryOptions = [{key: "DEFAULT", text: "DEFAULT", value: "DEFAULT"}];
        const currentCountryCodes = Object.keys(countries);
        for (const countryCode of Object.keys(CountryCodes)) {
            if (!currentCountryCodes.includes(countryCode.toUpperCase())) {
                updatedNewCountryOptions.push({key: countryCode.toUpperCase(), text: `${countryCode.toUpperCase()} - ${CountryCodes[countryCode]}`, value: countryCode.toUpperCase()});
            } else {
                updatedExistingCountryOptions.push({key: countryCode.toUpperCase(), text: countryCode.toUpperCase(), value: countryCode.toUpperCase()});
            }
        }
        setNewCountryOptions(updatedNewCountryOptions);
        setExistingCountryOptions(updatedExistingCountryOptions);
    }, [JSON.stringify(countries)]);

    useEffect(function onNewClicked() {
        if (newClicked) {
            setSelectedCountry({});
        }
    }, [newClicked]);

    const refreshCountries = () => {
        setLoading(true);
        setLoadingErrorMessage("");
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        AccioDataProvider.getMetadata().then(response => {
            console.log("AccioMaturityMasterDetailView.refreshCountries getMetadata response: ", response)
            if (response.hasOwnProperty("error")) {
                console.error(response.error);
                setLoadingErrorMessage("There was an error loading Accio metadata.");
                setCountries({});
                return;
            }
            if (!response.hasOwnProperty("metadata") || !response.metadata.hasOwnProperty("age_of_maturity")) {
                setLoadingErrorMessage("There was an error loading Accio metadata.");
                setCountries({});
                return;
            }

            const updatedCountries = {};
            updatedCountries.DEFAULT = Object.assign({}, response.metadata.age_of_maturity["DEFAULT"]);
            delete response.metadata.DEFAULT;
            for (const country of Object.keys(response.metadata.age_of_maturity)) {
                updatedCountries[country] = Object.assign({}, response.metadata.age_of_maturity[country]);
            }
            setCountries(Object.assign({}, updatedCountries));
            setSelectedCountry({});
        }).catch(error => {
            console.error(error);
            setLoadingErrorMessage("There was an error loading Accio metadata.");
        }).finally(() => {
            setLoading(false);
        });
    };

    const saveSettings = (countryCode, countryName, ageOfMaturity, paddingDays) => {
        setSaveErrorMessage("");
        setSaveSuccessMessage("");
        setSaving(true);
        const countriesToSave = Object.assign(
            {},
            countries,
            {
                [countryCode]: {
                    name: countryName,
                    padding_days: parseInt(paddingDays),
                    maturity_age: parseInt(ageOfMaturity)
                }
            }
        );

        console.log("countries currently: ", countries, "countries to save: ", countriesToSave);
        AccioDataProvider.setMetadata("age_of_maturity", countriesToSave).then(response => {
            console.log(response);
            setNewClicked(false);
            refreshCountries();
            setSaveSuccessMessage("Age-of-maturity settings have been successfully saved for Accio.");
        }).catch(error => {
            setSaveErrorMessage("There was an error saving age-of-maturity settings for Accio.");
            console.error(error);
        }).finally(() => {
            setSaving(false);
        });
    };

    const deleteSelectedCountry = () => {
        const selectedCountryCode = selectedCountry.abbreviation;
        const updatedMaturitySettings = {};
        for (const key of Object.keys(countries)) {
            if (key !== selectedCountryCode) {
                updatedMaturitySettings[key] = countries[key];
            }
        }
        setSaving(true);
        AccioDataProvider.setMetadata("age_of_maturity", updatedMaturitySettings).then(response => {
            console.log("AccioMaturityMasterDetailView.deleteSelectedCountry: received response ", response);
            refreshCountries();
            setSaveSuccessMessage(`Maturity settings for the country code ${selectedCountryCode} were successfully deleted.`);
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage(`There was an error deleting maturity settings for the country code ${selectedCountryCode}.`);
        }).finally(() => {
            setSaving(false);
        })
    };

    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Grid.Column width={4}>
                    <AccioCountrySelector
                        countries={countries}
                        refreshCountries={refreshCountries}
                        newClicked={newClicked}
                        setNewClicked={setNewClicked}
                        loading={loading}
                        setSelectedCountry={setSelectedCountry}
                        module={props.module}
                        service={props.service}
                        scope="all"
                        user={props.user}
                        permissions={props.permissions}
                    />
                </Grid.Column>
                <Grid.Column width={12}>
                    <AccioMaturityDetailView
                        selectedCountry={selectedCountry}
                        loading={loading}
                        saving={saving}
                        loadingErrorMessage={loadingErrorMessage}
                        saveErrorMessage={saveErrorMessage}
                        saveSuccessMessage={saveSuccessMessage}
                        newClicked={newClicked}
                        saveSettings={saveSettings}
                        deleteSelectedCountry={deleteSelectedCountry}
                        CountryCodes={CountryCodes}
                        newCountryOptions={newCountryOptions}
                        existingCountryOptions={existingCountryOptions}
                        module={props.module}
                        service={props.service}
                        scope="all"
                        user={props.user}
                        permissions={props.permissions}
                        userCanEdit={userCanEdit}
                    />
                </Grid.Column>
            </Grid.Row>

        </Grid>
    );
};
