import React, {useState, useEffect} from "react";
import {Dimmer, Grid, Loader} from "semantic-ui-react";
import AccioDataProvider from "../../../Services/AccioDataProvider";
import AccioAdminSelector from "./AccioAdminSelector";
import AccioAdminDetailView from "./AccioAdminDetailView";

export default function AccioAdminMasterDetailView(props) {
    const [metadata, setMetadata] = useState({});
    const [modifiableSettings, setModifiableSettings] = useState([]);
    const [selectedSettingName, setSelectedSettingName] = useState("");
    const [selectedSetting, setSelectedSetting] = useState({});
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [cacheClearInProgress, setCacheClearInProgress] = useState(false);
    const [cacheClearSuccessful, setCacheClearSuccessful] = useState(false);
    const [cacheRequestComplete, setCacheRequestComplete] = useState(false);

    useEffect(function initAccioSettings() {
        AccioDataProvider.init(process.env.ACCIO_URL);
        loadSettings();
    }, []);

    const loadSettings = () => {
        setLoading(true);
        AccioDataProvider.getMetadata().then(response => {
            if (response.hasOwnProperty("error")) {
                setErrorMessage("There was an error retrieving the settings from Accio.");
                console.error(response.error);
            } else if (response.hasOwnProperty("metadata")) {
                setMetadata(response.metadata);
            } else {
                setMetadata({});
            }
        }).catch(error => {
            setErrorMessage("There was an error retrieving the settings from Accio.");
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(function onMetadataUpdate() {
        if (metadata.hasOwnProperty("modifiable_settings")) {
            setModifiableSettings(metadata.modifiable_settings);
        } else {
            setModifiableSettings([]);
        }
    }, [JSON.stringify(metadata)]);

    useEffect(() => {
        console.log("modifiable settings: ", modifiableSettings);
    }, [JSON.stringify(modifiableSettings)]);

    useEffect(function onSelectedSettingNameUpdate() {
        console.log("selected setting name: ", selectedSettingName, "metadata value: ", metadata[selectedSettingName]);
        setSelectedSetting(metadata.hasOwnProperty(selectedSettingName) ? metadata[selectedSettingName] : {});
    }, [JSON.stringify(metadata), selectedSettingName]);

    const saveSettings = settingValue => {
        setSaving(true);
        AccioDataProvider.setMetadata(selectedSettingName, settingValue).then(response => {
            console.log("AccioAdminMasterDetailView.saveSettings: response: ", response);
            loadSettings();
            setSuccessMessage(`You have successfully saved the ${selectedSettingName} setting.`);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setSaving(false);
        });
    };

    const clearCache = () => {
        setCacheClearSuccessful(false);
        setCacheClearInProgress(true);
        AccioDataProvider.submitClearCache().then(r => {
            console.log("AccioAdminMasterDetailView.clearCache: response: ", r);
            if (r['status'] === "COMPLETED") {
                console.log("AccioAdminMasterDetailView.clearCache: SUCCESS! ", r.status);
                setCacheClearSuccessful(true);
            }
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setCacheClearInProgress(false);
            console.log("AccioAdminMasterDetailView.clearCache Complete.", "");
            setCacheRequestComplete(true);
        });
    };

    return (
        <Grid className="masterContainer">
            <Dimmer active={cacheClearInProgress}>
                <Loader active={cacheClearInProgress}>Clearing Cache...</Loader>
            </Dimmer>
            <Grid.Column width={5}>
                <AccioAdminSelector
                    settings={modifiableSettings}
                    loadSettings={loadSettings}
                    selectedSettingName={selectedSettingName}
                    setSelectedSettingName={setSelectedSettingName}
                    setSuccessMessage={setSuccessMessage}
                    setErrorMessage={setErrorMessage}
                    clearCache={clearCache}
                    cacheClearSuccessful={cacheClearSuccessful}
                    setCacheClearSuccessful={setCacheClearSuccessful}
                    cacheRequestComplete={cacheRequestComplete}
                    setCacheRequestComplete={setCacheRequestComplete}
                />
            </Grid.Column>
            <Grid.Column width={11}>
                <AccioAdminDetailView
                    selectedSettingName={selectedSettingName}
                    selectedSetting={selectedSetting}
                    service={props.service}
                    module={props.module}
                    user={props.user}
                    permissions={props.permissions}
                    loading={loading}
                    saving={saving}
                    successMessage={successMessage}
                    errorMessage={errorMessage}
                    saveSettings={saveSettings}
                />
            </Grid.Column>
        </Grid>
    )
};
