import React, {useEffect, useState} from "react";
import {Button, Card, Form, Grid, Icon, Input, List, Divider, Header} from "semantic-ui-react";
import AccioAdminClearCacheMenu from "./AccioAdminClearCacheMenu";

export default function AccioAdminSelector(props) {
    const [filter, setFilter] = useState("");
    const [filteredSettings, setFilteredSettings] = useState([]);

    useEffect(function updateFilteredCountries() {
        const updatedSettings = [];

        for (const setting of props.settings) {
            if (setting.toLowerCase().includes(filter.toLowerCase())) {
                updatedSettings.push(setting);
            }
        }

        setFilteredSettings(updatedSettings);
    }, [filter, JSON.stringify(props.settings)]);

    const refreshButtonClicked = () => {
        props.setSuccessMessage("");
        props.setErrorMessage("");
        props.loadSettings();
    };

    const selectNewSetting = settingName => {
        props.setSuccessMessage("");
        props.setErrorMessage("");
        props.setSelectedSettingName(settingName);
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column>
                <Grid.Row>
                    <Header as='h4'>Manage Settings</Header>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field
                                control={Input}
                                label="Filter"
                                value={filter}
                                placeholder="Filter Settings"
                                onChange={(event, {value}) => setFilter(value)}
                                fluid
                            />
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Button onClick={refreshButtonClicked} icon primary size="medium" fluid>&nbsp;<Icon name="refresh"/>&nbsp;Refresh Settings</Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    <List style={{maxHeight: "67vh", overflowY: "auto"}} selection>
                        {
                            filteredSettings.map(setting =>
                                <List.Item
                                    key={setting}
                                    onClick={() => selectNewSetting(setting)}
                                    active={props.selectedSettingName === setting}
                                >
                                    <List.Content>
                                        <Card fluid>
                                            <Card.Content>
                                                <Card.Description>
                                                    <h5>{setting}</h5>
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </List.Content>
                                </List.Item>
                            )
                        }
                    </List>
                </Grid.Row>
                <Divider />
                <Header as='h4'>Cache Management</Header>
                <Grid.Row>
                    <AccioAdminClearCacheMenu
                        clearCache={props.clearCache}
                        cacheClearSuccessful={props.cacheClearSuccessful}
                        setCacheClearSuccessful={props.setCacheClearSuccessful}
                        cacheRequestComplete={props.cacheRequestComplete}
                        setCacheRequestComplete={props.setCacheRequestComplete}
                    />
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
