import React, {useEffect, useState} from "react";
import {Button, Card, Container, Form, Grid, Icon, Input, List, Message, Segment} from "semantic-ui-react";
import Flag from "react-world-flags";
import ContingentButton from "../../ContingentButton";

export default function AccioCountrySelector(props) {
    const [filter, setFilter] = useState("");
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(function updateFilteredCountries() {
        const updatedFilteredCountries = [];

        for (const abbreviation of Object.keys(props.countries)) {
            const country = Object.assign(props.countries[abbreviation]);
            if (abbreviation.includes(filter) || (country.hasOwnProperty("name") && country.name.toString().toLowerCase().includes(filter.toLowerCase()))) {
                updatedFilteredCountries.push(Object.assign({}, props.countries[abbreviation], {abbreviation}));
            }
        }

        setFilteredCountries(updatedFilteredCountries);
    }, [filter, JSON.stringify(props.countries)]);

    return (
        <Grid className="masterContainer">
            <Grid.Column>
                <Grid.Row>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <ContingentButton
                                    positive
                                    icon
                                    onClick={() => props.setNewClicked(true)}
                                    fluid
                                    module={props.module}
                                    service={props.service}
                                    scope="all"
                                    user={props.user}
                                    allPermissions={props.permissions}
                                ><Icon name="plus"/>&nbsp;Add Country</ContingentButton>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                control={Input}
                                label="Country"
                                value={filter}
                                placeholder="Filter Countries"
                                onChange={(event, {value}) => setFilter(value)}
                                fluid
                            />
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Button onClick={() => props.refreshCountries()} icon primary size="medium" fluid>&nbsp;<Icon name="refresh"/>&nbsp;Refresh Countries</Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    <List style={{maxHeight: "67vh", overflowY: "auto"}}>
                        {
                            filteredCountries.map(country =>
                                <List.Item
                                    key={country.abbreviation}
                                    onClick={() => props.setSelectedCountry(country)}
                                    active={props.setSelectedCountry.abbreviation === country.abbreviation}
                                >
                                    <List.Content>
                                        <Card fluid>
                                            <Card.Content>
                                                <Card.Description>
                                                    <Grid>
                                                        <Grid.Column width={4}>
                                                            <Flag
                                                                code={country.abbreviation.toLowerCase()}
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    align: "center"
                                                                }}
                                                                fallback={<Flag code="un" />}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={12}>
                                                            <h5>{country.abbreviation}</h5>
                                                            {country.hasOwnProperty("name") ? country.name : ""}
                                                        </Grid.Column>
                                                    </Grid>
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </List.Content>
                                </List.Item>
                            )
                        }
                    </List>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
