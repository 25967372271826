import React, {useState, useEffect} from "react";
import {Button, Container, Form, Icon, Input, Message, TextArea} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";

export default function AccioEventsAdminDetailPane(props) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [points, setPoints] = useState(0);
    const [lastUpdate, setLastUpdate] = useState(0);

    useEffect(function onClickNew() {
        setName("");
        setDescription("");
        setPoints("");
        setLastUpdate(0);
    }, [props.newClicked]);

    useEffect(function onSelectedEventUpdate() {
        setName(props.selectedEvent.name || "");
        setDescription(props.selectedEvent.description || "");
        setPoints(props.selectedEvent.points || 0);
        setLastUpdate(props.selectedEvent.lastUpdate || 0);
    }, [props.selectedEvent]);
    return (
        <Container>
            {
                props.newClicked || Object.keys(props.selectedEvent).length > 0 ?
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <ContingentButton
                                    primary
                                    floated="left"
                                    onClick={() => props.saveEvent(name, description, points)}
                                    service={props.service}
                                    module={props.module}
                                    scope="all"
                                    user={props.user}
                                    allPermissions={props.permissions}
                                >Save Event</ContingentButton>
                            </Form.Field>
                            <Form.Field>
                                <ContingentButton
                                    color="red"
                                    floated="right"
                                    onClick={props.onClickDelete}
                                    service={props.service}
                                    module={props.module}
                                    scope="all"
                                    user={props.user}
                                    allPermissions={props.permissions}
                                >Delete Event</ContingentButton>
                            </Form.Field>
                        </Form.Group>
                        {
                            props.saving ?
                                <Message color="blue" icon>
                                    <Icon name="spinner" loading />
                                    <Message.Content>Saving...</Message.Content>
                                </Message> : ""
                        }
                        {
                            props.successMessage ?
                                <Message color="green" icon>
                                    <Icon name="check circle" />
                                    <Message.Content>{props.successMessage}</Message.Content>
                                </Message> : ""
                        }
                        {
                            props.errorMessage ?
                                <Message color="red" icon>
                                    <Icon name="x" />
                                    <Message.Content>{props.errorMessage}</Message.Content>
                                </Message> : ""
                        }
                        <Form.Field
                            label="Name"
                            control={Input}
                            value={name}
                            disabled={!props.newClicked}
                            onChange={(event, {value}) => setName(value)}
                        />
                        <Form.Field
                            label="Description"
                            control={TextArea}
                            value={description}
                            onChange={(event, {value}) => setDescription(value)}
                        />
                        <Form.Field
                            label="Points"
                            control={Input}
                            type="number"
                            value={points}
                            onChange={(event, {value}) => setPoints(value)}
                        />
                    </Form> :
                    <Message icon color="yellow">
                        <Icon name="info" />
                        <Message.Content>
                            Please select an event or create a new one.
                        </Message.Content>
                    </Message>
            }
        </Container>
    );
};
