import React, {useState, useEffect} from "react";
import {Select, Form, Grid, Icon, Button, Segment, Header} from "semantic-ui-react";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import ReactTable from "react-table-v6";
import AccioEventsUserTotal from "./AccioEventsUserTotal";
import selectTableHOC from "react-table-v6/lib/hoc/selectTable";
import treeTableHOC from "react-table-v6/lib/hoc/treeTable";


const SelectTable = selectTableHOC(treeTableHOC(ReactTable))

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};


export default function AccioEventsAudit(props) {
    const [userIds, setUserIds] = useState([]);
    const [userIdOptions, setUserIdOptions] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventOptions, setEventOptions] = useState([]);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [loading, setLoading] = useState(false);
    const [auditData, setAuditData] = useState([]);
    const [selectedRows, setSelectedRows] = useState({});
    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedEvents, setSelectedEvents] = useState({});
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
    const [enableExportButton, setEnableExportButton] = useState(false);
    const [userTotals, setUserTotals] = useState({});
    const [searchContainsUserId, setSearchContainsUserId] = useState(false);

    useEffect(function initEventOptions() {
        props.AccioDataProvider.getMetadata().then(response => {
            const updatedEventOptions = [];
            if (response.hasOwnProperty("metadata") && response.metadata.hasOwnProperty("events")) {
                for (const event of Object.keys(response.metadata.events)) {
                    updatedEventOptions.push({key: event, text: event, value: event});
                }
            }
            setEventOptions(updatedEventOptions);
        }).catch(error => {
            console.error(error);
            setEventOptions([]);
        });
    }, []);

    useEffect(function updateDeleteButton() {
        let rowsAreSelected = Object.keys(selectedEvents).length > 0;
        setDeleteButtonDisabled(!rowsAreSelected);
    }, [selectedEvents]);

    const updateSelectedEventsFromRow = (id, hmm, row) => {
        if (Object.keys(selectedEvents).includes(id)) {
            const {[id]: undefined, ...updatedSelectedEvents} = selectedEvents;
            setSelectedEvents(updatedSelectedEvents);
        } else {
            let newEvent = {};
            newEvent[id] = {"user_id": row['user_id'], "event_id": id}
            const updatedEvents = {...newEvent, ...selectedEvents};
            setSelectedEvents(updatedEvents);
        }
    };

    const performSearch = () => {
        setLoading(true);
        return props.AccioDataProvider.getEvents(userIds, events, start, end).then(response => {
            console.log("AccioEventsAuditMasterDetailView.performSearch: response: ", response);
            if (response.hasOwnProperty("error")) {
                console.error(response.error);
                setAuditData([]);
            } else {
                if (Object.keys(userIds).length > 0) {
                    calcUserTotals(response.results);
                    setSearchContainsUserId(true);
                } else {
                    setSearchContainsUserId(false);
                }
                setAuditData(response.results);
                setEnableExportButton(response.results.length > 0);
            }
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const deleteSelected = () => {
        setLoading(true);
        let allEvents = [];
        if (Object.keys(selectedEvents).length > 0) {
            for (const [eventId, event] of Object.entries(selectedEvents)) {
                allEvents.push(eventId)
            }
        }
        return props.AccioDataProvider.submitDelete(allEvents).then(response => {
            console.log(`AccioEventsAuditMasterDetailView.deleteSelected, response: ${JSON.stringify(response)}`);
            if (response.hasOwnProperty("error")) {
                console.error(response.error);
            } else {
                setSelectedEvents([]);
                setAuditData([]);
                performSearch();
            }
        }).catch(error => {
            console.error(error);
            setLoading(false);
        }).finally( () => {
            setLoading(false);
        })
    };

    const calcUserTotals = (result) => {
        let curUserTotals = {};
        for (let item of result) {
            if (curUserTotals.hasOwnProperty(item.user_id)){
                curUserTotals[item.user_id] += item.points;
            } else {
                curUserTotals[item.user_id] = item.points;
            }
        }
        setUserTotals(curUserTotals);
        if (Object.keys(userTotals).length === 1){
            setSearchContainsUserId(true);
        }
    }

    const downloadCSV = () => {
        let readableAuditData = [ ...auditData ];
        readableAuditData.forEach(function(item) {
            let readableDate = new Date(Number(item.timestamp) * 1000);
            item.timestamp = readableDate.toISOString();
        });


        let csvContent = generateCSV(readableAuditData);
        const blob = new Blob([csvContent], {type: "text/csv"});
        const a = document.createElement("a");
        a.download = "results.csv";
        a.href = window.URL.createObjectURL(blob);
        a.click();
    };

    const generateCSV = () => {
        let headers = ["User, Event, Points, Timestamp"];
        let auditRows = auditData.reduce((acc, auditEvent) => {
            const { user_id, event_id, points, timestamp } = auditEvent;
            acc.push([user_id, event_id, points, timestamp].join(','));
            return acc
        }, [])
        return [...headers, ...auditRows].join("\n");
    };

    const clearFilters = () => {
        setUserIds([]);
        setEvents([]);
        setStart(null);
        setEnd(null);
        setSearchContainsUserId(false);
    };

    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Grid.Column width={3} style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                    <Form width={16}>
                        <Form.Field>
                            <Button color="blue" onClick={performSearch} fluid>Search</Button>
                        </Form.Field>
                        <Form.Field>
                            <Form.Field>
                                <Button fluid color="black" onClick={clearFilters}>Clear Filters</Button>
                            </Form.Field>
                        </Form.Field>
                        <Form.Field
                            label="User IDs"
                            control={Select}
                            multiple
                            search
                            allowAdditions
                            options={userIdOptions}
                            value={userIds}
                            onChange={(event, {value}) => setUserIds(value)}
                            onAddItem={(event, {value}) => setUserIdOptions(userIdOptions.concat({key: value, text: value, value}))}
                        />
                        <Form.Field
                            label="Events"
                            control={Select}
                            multiple
                            search
                            options={props.eventOptions}
                            value={events}
                            onChange={(event, {value}) => setEvents(value)}
                        />
                        <Form.Field>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <label>Start Time</label>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    id="start"
                                    value={start}
                                    onChange={setStart}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                        <Form.Field width={16}>
                            <label>End Time</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    id="end"
                                    value={end}
                                    onChange={setEnd}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                        <Form.Button fluid color="red" disabled={deleteButtonDisabled} onClick={deleteSelected} >
                            <Button.Content>Delete Selected Events</Button.Content>
                        </Form.Button>
                        <Form.Field>
                            <Button animated="fade" color="gray" onClick={downloadCSV} disabled={!enableExportButton} fluid>
                                <Button.Content visible>Export Results to CSV</Button.Content>
                                <Button.Content hidden>
                                    <Icon name="download" />
                                </Button.Content>
                            </Button>
                        </Form.Field>
                    </Form>
                    {searchContainsUserId ? <AccioEventsUserTotal user_totals={userTotals}/> : null }
                </Grid.Column>
                <Grid.Column width={13} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "auto"}}>
                    <Grid.Row>
                        <SelectTable
                            columns={[
                                {
                                    Header: "User ID",
                                    accessor: "user_id"
                                },
                                {
                                    Header: "Event Name",
                                    accessor: "event_id"
                                },
                                {
                                    Header: "Points",
                                    accessor: "points"
                                },
                                {
                                    Header: "Timestamp",
                                    accessor: "timestamp",
                                    filterable: false,
                                    defaultSort:"desc",
                                    Cell: row => {
                                        return <span>{new Date(row.original.timestamp * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>;
                                    }
                                }
                            ]}
                            data={auditData}
                            loading={loading}
                            selectAll={false}
                            toggleAll={false}
                            toggleSelection={(_id, hmm, row) => updateSelectedEventsFromRow(_id, hmm, row)}
                            selectType="checkbox"
                            filterable={true}
                            defaultSorted={[
                                {id: "timestamp", desc: true}
                            ]}
                            defaultPageSize={200}
                        />
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
