import React, {Component} from "react";
import {Container, Form, Grid, Icon, Message} from "semantic-ui-react";
import JSONEditor from "jsoneditor";
import ContingentButton from "../../ContingentButton";

export default class AccioAdminDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settingValue: {}
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.selectedSetting) !== JSON.stringify(prevProps.selectedSetting)) {
            console.log("updated selected setting: ", this.props.selectedSetting);
            this.setState({settingValue: this.props.selectedSetting});
        }

        if (JSON.stringify(this.state.settingValue) !== JSON.stringify(prevState.settingValue)) {
            console.log("updated setting value: ", this.state.settingValue);
            this.editor.set(this.state.settingValue);
        }
    }

    componentDidMount() {
        this.editor = new JSONEditor(this.settingContainer, {mode: "code"});
        this.editor.set(this.state.settingValue);
    }

    render() {
        return (
            <Grid>
                {
                    this.props.loading ?
                        <Message icon color="yellow">
                            <Icon name="spinner" loading />
                            <Message.Content>Loading settings...</Message.Content>
                        </Message> :
                    this.props.saving ?
                        <Message icon color="blue">
                            <Icon name="spinner" loading />
                            <Message.Content>Saving settings...</Message.Content> :
                        </Message> : ""
                }
                {
                    this.props.successMessage.length > 0 ?
                        <Message icon color="green">
                            <Icon name="check" />
                            <Message.Content>{this.props.successMessage}</Message.Content>
                        </Message> : ""
                }
                {
                    this.props.errorMessage.length > 0 ?
                        <Message icon color="red">
                            <Icon name="x" />
                            <Message.Content>{this.props.errorMessage}</Message.Content>
                        </Message> : ""
                }
                <Container>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <ContingentButton
                                    service={this.props.service}
                                    module={this.props.module}
                                    scope="all"
                                    allPermissions={this.props.permissions}
                                    user={this.props.user}
                                    floated="left"
                                    primary
                                    onClick={() => this.props.saveSettings(this.editor.get())}
                                >
                                    <Icon name="save"/>&nbsp;Save Setting
                                </ContingentButton>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div style={{height: "70vh"}} ref={elem => this.settingContainer = elem} />
                        </Grid.Column>
                    </Grid.Row>
                </Container>
            </Grid>
        );
    }
};
