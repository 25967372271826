import React, {useState, useEffect} from "react";
import {Select, Form, Grid, Icon, Button, Card, Segment, Header} from "semantic-ui-react";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import ReactTable from "react-table-v6";
import selectTableHOC from "react-table-v6/lib/hoc/selectTable";
import treeTableHOC from "react-table-v6/lib/hoc/treeTable";

export default function AccioEventsUserTotal(props) {

    const generateUserTotalCards = (userTotals) => {
        let userTotalCards = [];
        for (let item of Object.entries(userTotals)) {
            userTotalCards.push(generateUserTotalCard(item[0], item[1]));
        }
        return userTotalCards;
    }

    const generateUserTotalCard = (userId, total) => {
        return <Card fluid
                     meta="User Total"
                     header={userId}
                     extra="(Total based on search filters)"
                     description={`Points: ${total || 0}`}/>
    }

    return (
        <div>
            <br/>
            {generateUserTotalCards(props.user_totals)}
        </div>
    );
};