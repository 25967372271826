import React, {useEffect, useState} from "react";
import {Button, Icon, Modal, Header, Grid} from "semantic-ui-react";

export default function AccioAdminClearCacheMenu(props) {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const confirmAndClearCache = () => {
        console.log("AccioAdminClearCacheMenu.confirmAndClearCache: Attempting to clear cache");
        setConfirmationModalOpen(false);
        props.clearCache();
    };

    const failedModalContent = () => {
        return (
            <Modal
                basic
                onClose={() => props.setCacheRequestComplete(false)}
                open={props.cacheRequestComplete && !props.cacheClearSuccessful}
                size='small'>
                <Header icon>
                    <Icon color={'red'} name='dont' />
                    Failed to clear cache
                </Header>
                <Modal.Content>
                    <p>
                        The request to clear cache was unsuccessful.  Please try again later.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' inverted onClick={() => props.setCacheRequestComplete(false)}>
                        <Icon name='checkmark' /> Ok
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    const successModalContent = () => {
        return (
            <Modal
                basic
                onClose={() => props.setCacheRequestComplete(false)}
                open={props.cacheRequestComplete && props.cacheClearSuccessful}
                size='small'>
                <Header icon>
                    <Icon color={'green'} name='check circle' />
                    Successfully Cleared Cache
                </Header>
                <Modal.Content>
                    <p>
                        The request to clear cache was successful.  It may take a few moments for all cached data to become invalidated.
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' inverted onClick={() => props.setCacheRequestComplete(false)}>
                        <Icon name='checkmark' /> Ok
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    return (
        <>
        <Modal
            basic
            onClose={() => setConfirmationModalOpen(false)}
            onOpen={() => setConfirmationModalOpen(true)}
            open={confirmationModalOpen}
            size='small'
            trigger={<Button fluid color='red'>Clear Cache</Button>}
        >
            <Header icon>
                <Icon name='refresh' />
                Clear Cache
            </Header>
            <Modal.Content>
                <p>
                    This will invalidate all cached data on the Accio service.  Do you want to continue?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red' inverted onClick={() => setConfirmationModalOpen(false)}>
                    <Icon name='remove' /> No
                </Button>
                <Button color='green' inverted onClick={confirmAndClearCache}>
                    <Icon name='checkmark' /> Yes
                </Button>
            </Modal.Actions>
        </Modal>
        {props.cacheClearSuccessful ? successModalContent() : failedModalContent()}
        </>
    );
};
